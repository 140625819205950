import { useCookies } from 'react-cookie'
import {
  ArrowLeftStartOnRectangleIcon, Bars3Icon, UserCircleIcon,
  ChartBarIcon,
  Cog6ToothIcon,
  AcademicCapIcon
} from '@heroicons/react/24/solid'
import { Link, useNavigate } from 'react-router-dom'
import { UserRole, useDescribeCurrentUserQuery } from '../state/apiSlice'
import { useSelector } from 'react-redux'
import State from '../state/state'
import Timer from './Timer'
import {
  AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton,
  ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography
} from '@mui/material'
import { useState } from 'react'

export default function TopBar() {
  const { data: user, error: userError } = useDescribeCurrentUserQuery('')
  const [_, __, clearCookie] = useCookies()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const time = useSelector(state => (state as State).timer.time)

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen)
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function logOut() {
    clearCookie('IdToken')
    clearCookie('AccessToken')
    clearCookie('RefreshToken')
    navigate('/login')
  }

  return <AppBar position="static">
    <Toolbar>
      {user && !userError && (
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleDrawer(true)}
        >
          <Bars3Icon className="h-6 w-6" />
        </IconButton>
      )}
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        <Link to="/">PAV</Link>
      </Typography>
      {
        time != null && <div className="flex-1 items-center justify-center">
          <Timer time={time} />
        </div>
      }
      {user && !userError && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <UserCircleIcon className="h-6 w-6" />
            <p className="mx-2 text-base">{user.UserId}</p>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >

            { user.Role === UserRole.DRIVER && (
                <MenuItem onClick={handleClose}>
                  <ChartBarIcon className="h-6 w-6" />
                  <Link to={`/stats/${user?.UserId}`}>Stats</Link>
                </MenuItem>
            )}
            <MenuItem onClick={() => {
              handleClose()
              logOut()
            }}>
              <ArrowLeftStartOnRectangleIcon className="h-6 w-6" />
              <Link to={`/stats/${user?.UserId}`}>Log out</Link>
            </MenuItem>
          </Menu>
        </div>
      )}
    </Toolbar>
    <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
      <Box className="h-full" sx={{ width: 250 }} onClick={toggleDrawer(false)}>
        <div className="h-full flex flex-col">
          <List>
            { user?.Role === UserRole.DRIVER ? <>
              <ListItem disablePadding>
                <Link to="/test" className="w-full">
                  <ListItemButton>
                    <ListItemIcon>
                      <AcademicCapIcon className="h-6 w-6" />
                    </ListItemIcon>
                    <ListItemText primary="Tests" />
                  </ListItemButton>
                </Link>
              </ListItem>
              <ListItem disablePadding>
                <Link to={`/stats/${user.UserId}`} className="w-full">
                  <ListItemButton>
                    <ListItemIcon>
                      <ChartBarIcon className="h-6 w-6" />
                    </ListItemIcon>
                    <ListItemText primary="Stats" />
                  </ListItemButton>
                </Link>
              </ListItem>
            </> : (
              <ListItem disablePadding>
                <Link to={`/account/${user?.AccountId}`} className="w-full">
                  <ListItemButton>
                    <ListItemIcon>
                      <Cog6ToothIcon className="h-6 w-6" />
                    </ListItemIcon>
                    <ListItemText primary="Manage account" />
                  </ListItemButton>
                </Link>
              </ListItem>
            )}
          </List>
          <div className="flex-1" />
          <Divider />
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => logOut()}>
                <ListItemIcon>
                  <ArrowLeftStartOnRectangleIcon className="h-6 w-6" />
                </ListItemIcon>
                <ListItemText primary="Log out" />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Box>
    </Drawer>
  </AppBar>
}