import { Divider } from "@mui/material"

interface CardProps {
  title: string
  text: string
  children: any
}

export default function Card(props: Readonly<CardProps>) {
  return <div
    className="border border-gray-400 bg-white rounded md:max-w-full w-full h-full overflow-hidden shadow-lg"
  >
    <div className="p-4 h-full flex flex-col justify-between leading-normal">
      <div className="flex-1 mb-3">
        <div className="text-gray-900 font-bold text-xl mb-2">
          {props.title}
        </div>
        <p className="text-gray-700 text-base">
          {props.text}
        </p>
      </div>
      <Divider />
      <div className="flex-1">
        <div className="flex flex-col items-center py-3">
          {props.children}
        </div>
      </div>
    </div>
  </div>
}
