import { useSelector } from 'react-redux'
import { GameCreatedResponse, useCreateGameMutation } from '../../state/apiSlice'
import { setError } from '../../state/errorSlice'
import { setIsLoading } from '../../state/loadingSlice'
import { store } from '../../state/store'
import { setTimer, startTimer } from '../../state/timerSlice'
import { GameKind } from '../../utils/gameKind'
import Button from '../Button'
import State from '../../state/state'

interface FocusGameStartButtonProps {
  phase: number
  gameKind: GameKind.FOCUS_GAME_KIND|GameKind.FULL_GAME_KIND
  setGameId: (gameId: string) => any
  startGame: () => any
  startPhase: () => any
  startRounds: (roundDuration: number) => any
  cleanupPhase: () => any
}

export default function FocusGameStartButton(
  props: Readonly<FocusGameStartButtonProps>
) {
  const [createGame, _] = useCreateGameMutation()
  const isLoading = useSelector(state => (state as State).loading.isLoading)

  function getStartButtonAction(phase: number) {
    switch (phase) {
      case 0:
        store.dispatch(setIsLoading(true))
        createGame(props.gameKind)
          .then((response) => {
            store.dispatch(props.setGameId(
              (response as { data: GameCreatedResponse }).data.GameId)
            )
            store.dispatch(props.startGame())
            store.dispatch(props.startPhase())
            store.dispatch(setTimer(
              parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!) / 1000
            ))
            store.dispatch(startTimer())
            store.dispatch(props.startRounds(
              parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_1_ROUND_DURATION!)
            ))
          })
          .catch(() => {
            store.dispatch(setError('An unexpected error occured. Please, try again!'))
          })
          .finally(() => {
            store.dispatch(setIsLoading(false))
          })

        break
      case 1:
        store.dispatch(props.startPhase())
        store.dispatch(setTimer(
          parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!) / 1000
        ))
        store.dispatch(startTimer())
        store.dispatch(props.startRounds(
          parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_2_ROUND_DURATION!)
        ))

        break
      case 2:
        store.dispatch(props.startPhase())
        store.dispatch(setTimer(
          parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!) / 1000
        ))
        store.dispatch(startTimer())
        store.dispatch(props.startRounds(
          parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_3_ROUND_DURATION!)
        ))

        break
      case 3:
        store.dispatch(props.cleanupPhase())

        break
    }
  }

  function getStartButtonText(
    phase: number, gameKind: GameKind.FOCUS_GAME_KIND|GameKind.FULL_GAME_KIND
  ) {
    let result

    switch (phase) {
      case 0:
        result = <>Start <br /> (Warmup Phase)</>

        break
      case 1:
        result = <>Continue <br /> (Fast Phase)</>

        break
      case 2:
        result = <>Continue <br /> (Cooldown Phase)</>

        break
      case 3:
        result = gameKind === GameKind.FOCUS_GAME_KIND ?
          <>New Test</> : <>Continue <br /> (Monotonity Tolerance Test)</>

        break
    }

    return result
  }

  return <Button
    type="button"
    kind="green"
    onClick={() => getStartButtonAction(props.phase)}
    disabled={isLoading}
  >
    {getStartButtonText(props.phase, props.gameKind)}
  </Button>
}
