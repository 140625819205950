import { createSlice } from '@reduxjs/toolkit'

export interface ErrorState {
  error: string
}

export const errorSlice = createSlice({
  name: 'Error',
  initialState: { error: '' },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload
    }
  }
})

export const { setError } = errorSlice.actions
