import { Link } from 'react-router-dom'
import Button from './Button'
import Card from './Card'

export default function Tests() {
  return <div className="max-w-prose md:max-w-full relative m-auto">
    <div className="flex justify-center align-center">
      <ul className="h-full w-full flex flex-col align-center justify-between md:grid md:grid-cols-2 md:place-items-stretch md:gap-12">
        <li className="m-3">
          <Card
            title="Full Test"
            text="Focus Test + Mononity Tolerance Test"
          >
            <Link to="/test/full" className="m-1 w-full">
              <Button type="submit" kind="green">Start test</Button>
            </Link>
          </Card>
        </li>

        <li className="m-3">
          <Card
            title="Focus Test"
            text="React to a wide variety of signals with the correct response."
          >
            <Link to="/test/focus" className="m-1 w-full">
              <Button type="submit" kind="green">Start test</Button>
            </Link>
            <Link to="/tutorial/focus" className="m-1 w-full">
              <Button type="submit" kind="grey">Tutorial</Button>
            </Link>
          </Card>
        </li>

        <li className="m-3">
          <Card
            title="Monotonity Tolerance Test"
            text="Classify symbols with small deviations as correct or incorrect for 7 minutes."
          >
            <Link to="/test/monotonity" className="m-1 w-full">
              <Button type="submit" kind="green">Start test</Button>
            </Link>
            <Link to="/tutorial/monotonity" className="m-1 w-full">
              <Button type="submit" kind="grey">Tutorial</Button>
            </Link>
          </Card>
        </li>
      </ul>
    </div>
  </div>
}
