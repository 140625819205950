import { ClockIcon } from '@heroicons/react/24/solid'

interface TimerProps {
  time: number
}

export default function Timer(props: Readonly<TimerProps>) {
  function formatTime(time: number) {
    return `${pad(Math.floor(time / 60))}:${pad(time % 60)}`
  }

  function pad(x: number) {
    return x < 10 ? `0${x}` : x
  }

  return <div className="flex items-center">
    <ClockIcon className="h-6 w-6" />
    <p className="ml-1">{formatTime(props.time)}</p>
  </div>
}
