import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Toast from './Toast'
import { useSelector } from 'react-redux'
import State from '../state/state'
import { useEffect } from 'react'
import Loading from './Loading'
import { useLazyDescribeCurrentUserQuery } from '../state/apiSlice'
import TopBar from './TopBar'
import { createPortal } from 'react-dom'
import useCurrentLocation from '../utils/useCurrentLocation'

export default function App() {
  const currentLocation = encodeURIComponent(useCurrentLocation())
  const [fetchCurrentUser] = useLazyDescribeCurrentUserQuery()

  const location = useLocation()
  const navigate = useNavigate()

  const isLoading = useSelector(state => (state as State).loading.isLoading)
  const error = useSelector(state => (state as State).error.error)

  useEffect(() => {
    fetchCurrentUser('')
      .then(response => {
        if (!response.data) {
          throw new Error()
        }
      })
      .catch(() => {
        if (location.pathname !== '/login') {
          navigate(`/login?next=${currentLocation}`)
        }
      })
  }, [location])

  return <>
    <TopBar />
    <main className="bg-slate-200 min-fill-under-top-bar p-1">
      <Outlet></Outlet>
      { isLoading ? createPortal(<Loading />, document.body) : null }
      {
        error ?
          createPortal(<Toast show={!!error} message={error} />, document.body) :
          null
      }
    </main>
  </>
}
