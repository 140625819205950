import { createSlice } from '@reduxjs/toolkit'
import { PedalKind } from '../utils/pedalKind'
import { PitchKind } from '../utils/pitchKind'
import { Color } from '../utils/color'
import { nextLedIndex } from './focusGame'

export interface FocusGameTutorialState {
  signal: null|Color|PitchKind|PedalKind,
  ledIndex: null|number,
  colorTutorialRound: number,
  pedalTutorialRound: number,
  pitchTutorialRound: number,
  slide: number
}

const initialState = {
  signal: null,
  ledIndex: null,
  colorTutorialRound: 0,
  pedalTutorialRound: 0,
  pitchTutorialRound: 0,
  slide: 1
} as FocusGameTutorialState

export const focusGameTutorialSlice = createSlice({
  name: 'FocusGameTutorial',
  initialState: initialState,
  reducers: {
    resetState: state => {
      state.ledIndex = initialState.ledIndex
      state.signal = initialState.signal
      state.slide = initialState.slide
    },
    nextSlide: state => {
      state.slide += 1
    },
    prevSlide: state => {
      state.slide -= 1
    },
    nextColorTutorialRound: state => {
      const colors = [
        Color.BLUE, Color.GREEN, Color.RED, Color.WHITE, Color.YELLOW
      ]

      if (state.colorTutorialRound < colors.length) {
        state.signal = colors[state.colorTutorialRound]
        state.ledIndex = nextLedIndex()
        state.colorTutorialRound += 1
      } else {
        state.signal = initialState.signal
        state.ledIndex = initialState.ledIndex
        state.colorTutorialRound = initialState.colorTutorialRound
        state.slide += 1
      }
    },
    nextPedalTutorialRound: state => {
      const pedals = [PedalKind.LEFT, PedalKind.RIGHT]

      if (state.pedalTutorialRound < pedals.length) {
        state.signal = pedals[state.pedalTutorialRound]
        state.pedalTutorialRound += 1
      } else {
        state.signal = initialState.signal
        state.pedalTutorialRound = initialState.pedalTutorialRound
        state.slide += 1
      }
    },
    nextPitchTutorialRound: state => {
      const pitches = [PitchKind.LOW, PitchKind.HIGH]

      if (state.pitchTutorialRound < pitches.length) {
        state.signal = pitches[state.pitchTutorialRound]
        state.pitchTutorialRound += 1
      } else {
        state.signal = initialState.signal
        state.pitchTutorialRound = initialState.pitchTutorialRound
        state.slide += 1
      }
    }
  }
})

export const {
  resetState, nextSlide, prevSlide, nextColorTutorialRound,
  nextPedalTutorialRound, nextPitchTutorialRound
} = focusGameTutorialSlice.actions
