import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import State from './state'
import { setIsLoading } from './loadingSlice'
import { GameKind } from '../utils/gameKind'
import { setError } from './errorSlice'
import { pavApi } from './apiSlice'
import { generateInitialSignalImages, nextSignal, nextSignalImage } from './monotonityGame'
import { PitchKind } from '../utils/pitchKind'

export enum MonotonityGameLifecycle {
  NULL = 'MonotonityGame/LifeCycle/Null',
  GAME_INITIALIZED = 'MonotonityGame/LifeCycle/GameInitialized',
  GAME_STARTED = 'MonotonityGame/LifeCycle/GameStarted',
  ROUND_ACTIVE = 'MonotonityGame/LifeCycle/RoundActive',
  ROUND_INACTIVE = 'MonotonityGame/LifeCycle/RoundInactive',
  GAME_ENDED = 'MonotonityGame/LifeCycle/GameEnded'
}

export interface MonotonityGameState {
  gameId: null|string
  lifecycle: MonotonityGameLifecycle
  signal: null|PitchKind
  signalImage: null|string
  initialSignalImages: string[]
  hits: number
  misses: number
}

const initialState = {
  gameId: null,
  lifecycle: MonotonityGameLifecycle.NULL,
  signal: null,
  signalImage: null,
  initialSignalImages: [],
  hits: 0,
  misses: 0
} as MonotonityGameState

function resetState(state: MonotonityGameState) {
  state.gameId = initialState.gameId
  state.lifecycle = initialState.lifecycle
  state.signal = initialState.signal
  state.signalImage = initialState.signalImage
  state.initialSignalImages = initialState.initialSignalImages
  state.hits = initialState.hits
  state.misses = initialState.misses
}

export const updateStats = createAsyncThunk(
  'MonotonityGame/UpdateStats',
  async (_, { getState, dispatch }) => {
    const state = getState() as State
    const gameId = state.monotonity.gameId
    const hits = state.monotonity.hits
    const misses = state.monotonity.misses

    dispatch(setIsLoading(true))
    pavApi.endpoints.updateGame.initiate({
      GameId: gameId!,
      Kind: GameKind.MONOTONITY_GAME_KIND,
      Hits: hits, Misses: misses
    })(dispatch, getState, {})
      .then(response => {
        if ((response as {error: any}).error) {
          throw new Error()
        }
      })
      .catch(() => {
        dispatch(setError('An unexpected error occured. Please try again!'))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
)

export const monotonityGameSlice = createSlice({
  name: 'MonotonityGame',
  initialState: initialState,
  reducers: {
    setGameId: (state, action) => {
      state.gameId = action.payload
    },
    initGame: state => {
      resetState(state)
    },
    startGame: state => {
      state.lifecycle = MonotonityGameLifecycle.GAME_STARTED
      state.initialSignalImages = generateInitialSignalImages()
      state.hits = 0
      state.misses = 0
    },
    startRound: state => {
      state.lifecycle = MonotonityGameLifecycle.ROUND_ACTIVE
      const signal = nextSignal()
      state.signal = signal
      const prevSignalImage = state.signalImage
      state.signalImage = nextSignalImage(
        signal, state.initialSignalImages, prevSignalImage
      )
    },
    userAction: (state, action) => {
      if (state.lifecycle === MonotonityGameLifecycle.ROUND_ACTIVE) {
        if (state.signal === action.payload) {
          state.hits += 1
        } else {
          state.misses += 1
        }
  
        state.lifecycle = MonotonityGameLifecycle.ROUND_INACTIVE
      }
    },
    endRound: state => {
      if (state.lifecycle === MonotonityGameLifecycle.ROUND_ACTIVE) {
        state.misses += 1
        state.lifecycle = MonotonityGameLifecycle.ROUND_INACTIVE
      }
    },
    endGame: state => {
      state.lifecycle = MonotonityGameLifecycle.GAME_ENDED
    }
  }
})

export const {
  initGame, startGame, startRound, userAction,
  endRound, endGame, setGameId
} = monotonityGameSlice.actions
