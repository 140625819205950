import { v4 as uuidv4 } from 'uuid'
import { Color } from '../utils/color'
import ThreeDButton, { ThreeDButtonKind, ThreeDButtonSize } from './ThreeDButton'
import { PedalKind } from '../utils/pedalKind'
import { PitchKind } from '../utils/pitchKind'

interface ControlPanelProps {
  markAction?: null|Color|PedalKind|PitchKind,
  onLowPitchButtonClick?: () => any,
  onHighPitchButtonClick?: () => any,
  onRedColorButtonClick?: () => any,
  onBlueColorButtonClick?: () => any,
  onWhiteColorButtonClick?: () => any,
  onGreenColorButtonClick?: () => any,
  onYellowColorButtonClick?: () => any,
  onLeftPedalButtonClick?: () => any,
  onRightPedalButtonClick?: () => any,
}

export default function ControlPanel(props: Readonly<ControlPanelProps>) {
  return (
    <>
      <div className="h-1/3 flex align-center justify-around">
        <ThreeDButton
          color={Color.BLACK}
          marked={props.markAction === PitchKind.LOW}
          onClick={props.onLowPitchButtonClick}
        />
        {
          [Color.RED, Color.BLUE].map(color => (
              <ThreeDButton
                key={uuidv4()}
                kind={ThreeDButtonKind.ROUND}
                marked={props.markAction === color}
                color={color}
                onClick={() => {
                  switch (color) {
                    case Color.RED:
                      if (props.onRedColorButtonClick) props.onRedColorButtonClick()

                      break
                    case Color.BLUE:
                      if (props.onBlueColorButtonClick) props.onBlueColorButtonClick()

                      break
                  }
                }}
              />
          ))
        }
        <ThreeDButton
          color={Color.WHITE}
          marked={props.markAction === PitchKind.HIGH}
          onClick={props.onHighPitchButtonClick}
        />
      </div>
      <div className="h-1/3 flex align-center justify-around">
        {
          [Color.WHITE, Color.GREEN, Color.YELLOW].map(color => (
              <ThreeDButton
                key={uuidv4()}
                color={color}
                kind={ThreeDButtonKind.ROUND}
                marked={props.markAction === color}
                onClick={() => {
                  switch (color) {
                    case Color.WHITE:
                      if (props.onWhiteColorButtonClick) props.onWhiteColorButtonClick()

                      break
                    case Color.GREEN:
                      if (props.onGreenColorButtonClick) props.onGreenColorButtonClick()

                      break
                    case Color.YELLOW:
                      if (props.onYellowColorButtonClick) props.onYellowColorButtonClick()

                      break
                  }
                }}
              />
          ))
        }
      </div>
      <div className="h-1/3 flex align-center justify-around relative p-2">
        <ThreeDButton
          color={Color.BLACK}
          size={ThreeDButtonSize.LARGE}
          onClick={props.onLeftPedalButtonClick}
          marked={props.markAction === PedalKind.LEFT}
        />
        <ThreeDButton
          color={Color.BLACK}
          size={ThreeDButtonSize.LARGE}
          onClick={props.onRightPedalButtonClick}
          marked={props.markAction === PedalKind.RIGHT}
        />
      </div>
    </>
  )
}
