import { useSelector } from 'react-redux'
import { GameKind } from '../../utils/gameKind'
import MonotonityGame from './MonotonityGameFragment'
import State from '../../state/state'
import {
  focusGameUserAction,
  initGame, nextStage, setGameId, startFocusGame,
  startFocusGamePhase, startFocusGameRounds
} from '../../state/fullGameSlice'
import { useEffect } from 'react'
import { store } from '../../state/store'
import FocusGameFragment from '../FocusGame/FocusGameFragment'
import { Color } from '../../utils/color'
import { PitchKind } from '../../utils/pitchKind'
import { PedalKind } from '../../utils/pedalKind'

export default function FullGame() {
  const stage = useSelector(state => (state as State).fullGame.stage)
  const lifecycle = useSelector(state => (state as State).fullGame.lifecycle)
  const phase = useSelector(state => (state as State).fullGame.focusGamePhase)
  const signal: null|Color|PitchKind|PedalKind = useSelector(
    state => (state as State).fullGame.focusGameSignal
  )
  const ledIndex = useSelector(state => (state as State).fullGame.focusGameLedIndex)

  useEffect(() => {
    store.dispatch(initGame())

    return () => { }
  }, [])

  return <>{
    stage === GameKind.FOCUS_GAME_KIND ?
      <FocusGameFragment
        lifecycle={lifecycle}
        phase={phase}
        signal={signal}
        ledIndex={ledIndex}
        gameKind={GameKind.FULL_GAME_KIND}
        setGameId={setGameId}
        startGame={startFocusGame}
        startPhase={startFocusGamePhase}
        startRounds={startFocusGameRounds}
        cleanupPhase={nextStage}
        userAction={focusGameUserAction}
      /> : <MonotonityGame />
  }</>
}
