import { setError } from "../state/errorSlice"
import { store } from "../state/store"

interface ToastProps {
  show: boolean
  message?: string
}

export default function Toast(props: Readonly<ToastProps>) {
  return <>
    {
      props.show ? (
        <div className="fixed h-20 bottom-5 w-full z-50">
          <div className="h-full flex justify-center">
            <div
              id="toast-danger"
              className="flex items-center p-4 text-gray-500 bg-white rounded-lg shadow-lg border-2"
              role="alert"
            >
              <div
                className="inline-flex items-center justify-center w-8 h-8 text-red-500 bg-red-100 rounded-lg"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"
                  />
                </svg>
                <span className="sr-only">Error icon</span>
              </div>
              <div className="mx-3 text-sm font-normal">
                {props.message ?? 'An unexpected error occured. Please try again later'}
              </div>
              <button
                type="button"
                className="bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
                data-dismiss-target="#toast-danger"
                aria-label="Close"
                onClick={() => store.dispatch(setError(''))}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      ) : null
    }
  </>
}
