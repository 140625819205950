import Button from './Button'
import GameDialog from './GameDialog'

interface TutorialDialogProps {
  show: boolean
  children: any
  next?: any
  prev?: any
}

export default function TutorialDialog(props: Readonly<TutorialDialogProps>) {
  return <GameDialog show={props.show}>
    <>
      <div className="bg-white p-6 pb-4">
        {props.children}
      </div>
      <div className="bg-gray-50 px-4 py-3 flex">
        <div className="flex m-3">
          <Button
            type="button"
            kind="grey"
            disabled={!props.prev}
            onClick={() => { if (props.prev) { props.prev() } }}
          >
            Back
          </Button>
        </div>
        <div className="flex m-3">
          <Button
            type="button"
            kind="green"
            disabled={!props.next}
            onClick={() => { if (props.next) { props.next() } }}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  </GameDialog>
}
