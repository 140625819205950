import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Color } from '../utils/color'
import { setIsLoading } from './loadingSlice'
import { GameKind } from '../utils/gameKind'
import { setError } from './errorSlice'
import State from './state'
import { pavApi } from './apiSlice'
import { nextLedIndex, nextSignal } from './focusGame'
import { PedalKind } from '../utils/pedalKind'
import { PitchKind } from '../utils/pitchKind'
import { clearTimer, setTimer } from './timerSlice'

export enum FocusGameLifecycle {
  NULL = 'FocusGame/LifeCycle/Null',
  GAME_INITIALIZED = 'FocusGame/LifeCycle/GameInitialized',
  GAME_STARTED = 'FocusGame/LifeCycle/GameStarted',
  PHASE_STARTED = 'FocusGame/LifeCycle/PhaseStarted',
  ROUND_ACTIVE = 'FocusGame/LifeCycle/RoundActive',
  ROUND_INACTIVE = 'FocusGame/LifeCycle/RoundInactive',
  PHASE_ENDED = 'FocusGame/LifeCycle/PhaseEnded',
  GAME_ENDED = 'FocusGame/LifeCycle/GameEnded'
}

export interface FocusGameState {
  gameId: null|string
  lifecycle: FocusGameLifecycle
  phase: number
  signal: null|Color|PitchKind|PedalKind
  ledIndex: null|number
  hits: number
  misses: number
}

const initialState = {
  gameId: null,
  lifecycle: FocusGameLifecycle.NULL,
  phase: 0,
  signal: null,
  ledIndex: null,
  hits: 0,
  misses: 0
} as FocusGameState

function resetState(state: FocusGameState) {
  state.gameId = initialState.gameId
  state.lifecycle = initialState.lifecycle
  state.phase = initialState.phase
  state.signal = initialState.signal
  state.ledIndex = initialState.ledIndex
  state.hits = initialState.hits
  state.misses = initialState.misses
}

let interval: null|NodeJS.Timer = null

export const startRounds = (roundDuration: number) => (dispatch: any, getState: any) => {
  setTimeout(() => {
    dispatch(endRounds())
    dispatch(endPhase())
    dispatch(updateStats(roundDuration))
    if (getState().focus.phase === 3) {
      dispatch(endGame())
    }
  }, parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!))

  interval = setInterval(() => {
    dispatch(endRound())
    dispatch(startRound())
  }, roundDuration)
}

export const endRounds = () => (dispatch: any, getState: any) => {
  if (interval) {
    clearInterval(interval)
  }

  dispatch(clearTimer())
  if (getState().focus.phase !== 3) {
    dispatch(setTimer(
      parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!) / 1000)
    )
  }
}

const updateStats = createAsyncThunk(
  'FocusGame/UpdateStats',
  async (roundDuration: number, { getState, dispatch }) => {
    const state = getState() as State
    const gameId = state.focus.gameId
    const hits = state.focus.hits
    const misses = state.focus.misses

    dispatch(setIsLoading(true))
    pavApi.endpoints.updateGame.initiate({
      GameId: gameId!,
      Kind: GameKind.FOCUS_GAME_KIND,
      RoundDuration: roundDuration,
      Hits: hits, Misses: misses
    })(dispatch, getState, {})
      .then((response) => {
        if ((response as {error: any}).error) {
          throw new Error()
        }

        // Reset hits & misses as they are already synced
        dispatch(resetStats())
      })
      .catch(() => {
        dispatch(setError('An unexpected error occured. Please try again!'))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
)

export const focusGameSlice = createSlice({
  name: 'FocusGame',
  initialState: initialState,
  reducers: {
    setGameId: (state, action) => {
      state.gameId = action.payload
    },
    initGame: state => {
      resetState(state)
    },
    startGame: state => {
      state.lifecycle = FocusGameLifecycle.GAME_STARTED
      state.hits = 0
      state.misses = 0
    },
    startPhase: state => {
      state.lifecycle = FocusGameLifecycle.PHASE_STARTED
      state.phase += 1
    },
    startRound: state => {
      state.lifecycle = FocusGameLifecycle.ROUND_ACTIVE
      const signal = nextSignal(state.signal)
      state.signal = signal

      if ([
        Color.BLUE, Color.GREEN, Color.RED, Color.WHITE, Color.YELLOW
      ].includes((signal as Color))) {
        state.ledIndex = nextLedIndex()
      } else {
        state.ledIndex = null
      }
    },
    userAction: (state, action) => {
      if (state.lifecycle === FocusGameLifecycle.ROUND_ACTIVE) {
        if (state.signal === action.payload) {
          state.hits += 1
        } else {
          state.misses += 1
        }

        state.lifecycle = FocusGameLifecycle.ROUND_INACTIVE
      }
    },
    resetStats: state => {
      state.hits = 0
      state.misses = 0
    },
    endRound: state => {
      if (state.lifecycle === FocusGameLifecycle.ROUND_ACTIVE) {
        state.misses += 1
        state.lifecycle = FocusGameLifecycle.ROUND_INACTIVE
      }
    },
    endPhase: state => {
      state.lifecycle = FocusGameLifecycle.PHASE_ENDED
      state.ledIndex = null
      state.signal = null
    },
    cleanupPhase: state => {
      state.phase = 0
    },
    endGame: state => {
      state.lifecycle = FocusGameLifecycle.GAME_ENDED
    }
  }
})

export const {
  setGameId, initGame, startGame, startPhase, startRound, userAction,
  endRound, endPhase, cleanupPhase, endGame, resetStats
} = focusGameSlice.actions
