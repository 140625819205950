import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Color } from '../utils/color'
import { GameKind } from '../utils/gameKind'
import {
  nextSignal as nextFocusGameSignal, nextLedIndex
} from './focusGame'
import {
  nextSignal as nextMonotonityGameSignal,
  nextSignalImage,
  generateInitialSignalImages
} from './monotonityGame'
import State from './state'
import { setIsLoading } from './loadingSlice'
import { setError } from './errorSlice'
import { pavApi } from './apiSlice'
import { PedalKind } from '../utils/pedalKind'
import { PitchKind } from '../utils/pitchKind'
import { clearTimer, setTimer } from './timerSlice'


export enum FullGameLifecycle {
  NULL = 'FullGame/LifeCycle/Null',
  GAME_INITIALIZED = 'FullGame/LifeCycle/GameInitialized',
  GAME_STARTED = 'FullGame/LifeCycle/GameStarted',
  PHASE_STARTED = 'FullGame/LifeCycle/PhaseStarted',
  ROUND_ACTIVE = 'FullGame/LifeCycle/RoundActive',
  ROUND_INACTIVE = 'FullGame/LifeCycle/RoundInactive',
  PHASE_ENDED = 'FullGame/LifeCycle/PhaseEnded',
  GAME_ENDED = 'FullGame/LifeCycle/GameEnded'
}

export default interface FullGameState {
  gameId: null|string
  stage: GameKind
  lifecycle: FullGameLifecycle
  focusGamePhase: number
  focusGameSignal: null|Color|PitchKind|PedalKind
  focusGameLedIndex: null|number
  monotonityGameSignal: null|PitchKind
  monotonityGameSignalImage: null|string
  monotonityGameInitialSignalImages: string[]
  hits: number
  misses: number
}

const initialState = {
  gameId: null,
  stage: GameKind.FOCUS_GAME_KIND,
  lifecycle: FullGameLifecycle.NULL,
  focusGamePhase: 0,
  focusGameSignal: null,
  focusGameLedIndex: null,
  monotonityGameSignal: null,
  monotonityGameSignalImage: null,
  monotonityGameInitialSignalImages: [],
  hits: 0,
  misses: 0
} as FullGameState

function resetState(state: FullGameState) {
  state.gameId = initialState.gameId
  state.stage = initialState.stage
  state.lifecycle = initialState.lifecycle
  state.focusGamePhase = initialState.focusGamePhase
  state.focusGameSignal = initialState.focusGameSignal
  state.focusGameLedIndex = initialState.focusGameLedIndex
  state.monotonityGameSignal = initialState.monotonityGameSignal
  state.monotonityGameSignalImage = initialState.monotonityGameSignalImage
  state.monotonityGameInitialSignalImages = (
    initialState.monotonityGameInitialSignalImages
  )
  state.hits = initialState.hits
  state.misses = initialState.misses
}

let interval: null|NodeJS.Timer = null

export const startFocusGameRounds = (roundDuration: number) => (dispatch: any, getState: any) => {
  setTimeout(() => {
    dispatch(endFocusGameRounds())
    dispatch(endFocusGamePhase())
    dispatch(updateFocusGameStats(roundDuration))
  }, parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!))

  interval = setInterval(() => {
    dispatch(endFocusGameRound())
    dispatch(startFocusGameRound())
  }, roundDuration)
}

export const endFocusGameRounds = () => (dispatch: any, getState: any) => {
  if (interval) {
    clearInterval(interval)
  }

  dispatch(clearTimer())
  if (getState().fullGame.focusGamePhase !== 3) {
    dispatch(setTimer(
      parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!) / 1000)
    )
  }
}

const updateFocusGameStats = createAsyncThunk(
  'FullGame/FocusGame/UpdateStats',
  async (roundDuration: number, { getState, dispatch }) => {
    const state = getState() as State
    const gameId = state.fullGame.gameId
    const hits = state.fullGame.hits
    const misses = state.fullGame.misses

    dispatch(setIsLoading(true))
    pavApi.endpoints.updateGame.initiate({
      GameId: gameId!,
      Kind: GameKind.FOCUS_GAME_KIND,
      RoundDuration: roundDuration,
      Hits: hits, Misses: misses
    })(dispatch, getState, {})
      .then((response) => {
        if ((response as {error: any}).error) {
          throw new Error()
        }

        // Reset hits & misses as they are already synced
        dispatch(resetStats())
      })
      .catch(() => {
        dispatch(setError('An unexpected error occured. Please try again!'))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
)

export const updateMonotonityGameStats = createAsyncThunk(
  'FullGame/MonotonityGame/UpdateStats',
  async (_, { getState, dispatch }) => {
    const state = getState() as State
    const gameId = state.fullGame.gameId
    const hits = state.fullGame.hits
    const misses = state.fullGame.misses

    dispatch(setIsLoading(true))
    pavApi.endpoints.updateGame.initiate({
      GameId: gameId!,
      Kind: GameKind.MONOTONITY_GAME_KIND,
      Hits: hits, Misses: misses
    })(dispatch, getState, {})
      .then(response => {
        if ((response as {error: any}).error) {
          throw new Error()
        }
      })
      .catch(() => {
        dispatch(setError('An unexpected error occured. Please try again!'))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
)

export const fullGameSlice = createSlice({
  name: 'FullGameSlice',
  initialState: initialState,
  reducers: {
    setGameId: (state, action) => {
      state.gameId = action.payload
    },
    initGame: state => {
      resetState(state)
    },
    startFocusGame: state => {
      state.lifecycle = FullGameLifecycle.GAME_STARTED
      state.hits = 0
      state.misses = 0
    },
    startFocusGamePhase: state => {
      state.lifecycle = FullGameLifecycle.PHASE_STARTED
      state.focusGamePhase += 1
    },
    startFocusGameRound: state => {
      state.lifecycle = FullGameLifecycle.ROUND_ACTIVE
      const signal = nextFocusGameSignal(state.focusGameSignal)
      state.focusGameSignal = signal

      if ([
        Color.BLUE, Color.GREEN, Color.RED, Color.WHITE, Color.YELLOW
      ].includes((signal as Color))) {
        state.focusGameLedIndex = nextLedIndex()
      } else {
        state.focusGameLedIndex = null
      }
    },
    focusGameUserAction: (state, action) => {
      if (state.lifecycle === FullGameLifecycle.ROUND_ACTIVE) {
        if (state.focusGameSignal === action.payload) {
          state.hits += 1
        } else {
          state.misses += 1
        }

        state.lifecycle = FullGameLifecycle.ROUND_INACTIVE
      }
    },
    resetStats: state => {
      state.hits = 0
      state.misses = 0
    },
    endFocusGameRound: state => {
      if (state.lifecycle === FullGameLifecycle.ROUND_ACTIVE) {
        state.misses += 1
        state.lifecycle = FullGameLifecycle.ROUND_INACTIVE
      }
    },
    endFocusGamePhase: state => {
      state.lifecycle = FullGameLifecycle.PHASE_ENDED
      state.focusGameLedIndex = null
      state.focusGameSignal = null
    },
    nextStage: state => {
      state.stage = GameKind.MONOTONITY_GAME_KIND
      state.lifecycle = FullGameLifecycle.NULL
    },
    startMonotonityGame: state => {
      state.lifecycle = FullGameLifecycle.GAME_STARTED
      state.monotonityGameInitialSignalImages = generateInitialSignalImages()
      state.hits = 0
      state.misses = 0
    },
    startMonotonityGameRound: state => {
      state.lifecycle = FullGameLifecycle.ROUND_ACTIVE
      const signal = nextMonotonityGameSignal()
      state.monotonityGameSignal = signal
      const prevSignalImage = state.monotonityGameSignalImage
      state.monotonityGameSignalImage = nextSignalImage(
        signal, state.monotonityGameInitialSignalImages, prevSignalImage
      )
    },
    monotonityGameUserAction: (state, action) => {
      if (state.lifecycle === FullGameLifecycle.ROUND_ACTIVE) {
        if (state.monotonityGameSignal === action.payload) {
          state.hits += 1
        } else {
          state.misses += 1
        }
  
        state.lifecycle = FullGameLifecycle.ROUND_INACTIVE
      }
    },
    endMonotonityGameRound: state => {
      if (state.lifecycle === FullGameLifecycle.ROUND_ACTIVE) {
        state.misses += 1
        state.lifecycle = FullGameLifecycle.ROUND_INACTIVE
      }
    },
    endMonotonityGame: state => {
      state.lifecycle = FullGameLifecycle.GAME_ENDED
    }
  }
})

export const {
  setGameId, initGame, startFocusGame, startFocusGamePhase, startFocusGameRound,
  focusGameUserAction, resetStats, endFocusGameRound, endFocusGamePhase,
  nextStage, startMonotonityGame, startMonotonityGameRound, monotonityGameUserAction,
  endMonotonityGameRound, endMonotonityGame
} = fullGameSlice.actions
