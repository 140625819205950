import { v4 as uuidv4 } from 'uuid'
import Led, { LedSize } from './Led'
import { Color } from '../../utils/color'
import { PedalKind } from '../../utils/pedalKind'
import ControlPanel from '../ControlPanel'
import { useSelector } from 'react-redux'
import State from '../../state/state'
import { store } from '../../state/store'
import {
  nextSlide, prevSlide, resetState, nextColorTutorialRound,
  nextPedalTutorialRound, nextPitchTutorialRound
} from '../../state/focusGameTutorialSlice'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import TutorialDialog from '../TutorialDialog'
import { PitchKind } from '../../utils/pitchKind'
import useSound from 'use-sound'
import lowPitch from './low_pitch.mp3'
import highPitch from './high_pitch.mp3'

export default function FocusGameTutorial() {
  const navigate = useNavigate()

  const [playLowPitch] = useSound(lowPitch)
  const [playHighPitch] = useSound(highPitch)

  const slide = useSelector(state => (state as State).focusTutorial.slide)
  let signal: null|Color|PitchKind|PedalKind = null
  signal = useSelector(state => {
    const s = (state as State).focusTutorial.signal

    if (signal !== s) {
      switch (s) {
        case PitchKind.LOW:
          playLowPitch()

          break
        case PitchKind.HIGH:
          playHighPitch()

          break
      }
    }

    return s
  })
  const ledIndex = useSelector(state => (state as State).focusTutorial.ledIndex)

  useEffect(() => {
    store.dispatch(resetState())

    return () => { store.dispatch(resetState()) }
  }, [])

  return <>
    <div className="fill-under-top-bar max-w-prose relative m-auto overflow-hidden">
      <div className="h-1/2 relative">
        <div className="h-2/3" style={{backgroundColor: '#8da7b5'}}>
          <div className="h-1/2 flex align-center justify-around">
            {
              [0, 1, 2, 3, 4].map(x => (
                <Led
                  key={uuidv4()}
                  color={signal as Color}
                  size={LedSize.SMALL}
                  active={ledIndex === x}
                />
              ))
            }
          </div>
          <div className="h-1/2 flex align-center justify-around">
            {
              [5, 6, 7, 8, 9].map(x => (
                <Led
                  key={uuidv4()}
                  color={signal as Color}
                  size={LedSize.SMALL}
                  active={ledIndex === x}
                />
              ))
            }
          </div>
        </div>
        <div className="h-1/3 flex align-center justify-around bg-slate-300 relative">
          <Led
            color={Color.WHITE}
            size={LedSize.LARGE}
            active={signal === PedalKind.LEFT}
          />
          <Led
            color={Color.WHITE}
            size={LedSize.LARGE}
            active={signal === PedalKind.RIGHT}
          />
        </div>
      </div>
      <div className="h-1/2 relative">
        <ControlPanel
          markAction={signal}
          onLowPitchButtonClick={() => {
            if (signal === PitchKind.LOW) {
              store.dispatch(nextPitchTutorialRound())
            }
          }}
          onHighPitchButtonClick={() => {
            if (signal === PitchKind.HIGH) {
              store.dispatch(nextPitchTutorialRound())
            }
          }}
          onRedColorButtonClick={() => {
            if (signal === Color.RED) {
              store.dispatch(nextColorTutorialRound())
            }
          }}
          onBlueColorButtonClick={() => {
            if (signal === Color.BLUE) {
              store.dispatch(nextColorTutorialRound())
            }
          }}
          onWhiteColorButtonClick={() => {
            if (signal === Color.WHITE) {
              store.dispatch(nextColorTutorialRound())
            }
          }}
          onGreenColorButtonClick={() => {
            if (signal === Color.GREEN) {
              store.dispatch(nextColorTutorialRound())
            }
          }}
          onYellowColorButtonClick={() => {
            if (signal === Color.YELLOW) {
              store.dispatch(nextColorTutorialRound())
            }
          }}
          onLeftPedalButtonClick={() => {
            if (signal === PedalKind.LEFT) {
              store.dispatch(nextPedalTutorialRound())
            }
          }}
          onRightPedalButtonClick={() => {
            if (signal === PedalKind.RIGHT) {
              store.dispatch(nextPedalTutorialRound())
            }
          }}
        />
      </div>
    </div>

    <TutorialDialog
      show={slide === 1}
      prev={() => navigate('/')}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        During the focus test, you will have to react to signals in a timely manner.
        There are both visual and audio signals, so don't forget to turn your volume up!
        Explore the different signals and how to react to them, on the next slides!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 2}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        The smaller leds on the upper panel can light up with different colors.
        Each of them can light up with white, red, blue, green or yellow color.
        Only one of them will light up at a time.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 3}
      prev={() => store.dispatch(prevSlide())}
      next={() => {
        store.dispatch(nextSlide())
        store.dispatch(nextColorTutorialRound())
      }}
    >
      <p>
        When one of the smaller leds on the upper panel lights up with a color,
        click on the button with that color on the control panel.
        Let's see some examples!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 5}
      prev={() => {
        store.dispatch(prevSlide())
        store.dispatch(nextColorTutorialRound())
      }}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        The larger leds on the lower panel can light up with white color.
        Either the left or the right one will light up at a time.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 6}
      prev={() => store.dispatch(prevSlide())}
      next={() => {
        store.dispatch(nextSlide())
        store.dispatch(nextPedalTutorialRound())
      }}
    >
      <p>
        When they do that, you will have to click on the corresponding "pedal".
        During the exam, you will have to operate the pedals with your legs.
        Let's see some examples!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 8}
      prev={() => {
        store.dispatch(prevSlide())
        store.dispatch(nextPedalTutorialRound())
      }}
      next={() => {
        store.dispatch(nextSlide())
        store.dispatch(nextPitchTutorialRound())
      }}
    >
      <p>
        When you hear a low pitch noise, click on the black
        rectangular button on the left of the control panel.
        When you hear a high pitch noise, click on the
        white rectangular button on the right of the control panel.
        Let's see some examples!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 10}
      prev={() => {
        store.dispatch(prevSlide())
        store.dispatch(nextPitchTutorialRound())
      }}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        Now, you should understand how to react to the different signals.
        Let's talk about speed.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 11}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        The focus test consists of 3 "phases".
        The 1st phase is the "warmup" phase. Signals will appear at a lower rate.
        The 2nd phase is the "fast" phase. Signals will follow one another very quickly.
        The 3rd and last phase is the "cooldown" phase. Signals will appear faster
        than during the warmup phase but not as fast as during 2nd phase.
        Each phase takes 2 minutes and 20 seconds, so the test will take 7 minutes.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 12}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        You will be rated based on the number of errors you made.
        A wrong reaction to a signal (e.g. clicking the red button, when the led is blue)
        or no reaction to a signal, both counts as an error.
        To pass the test, your error rate has to be lower than the threshold in each phase.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 13}
      prev={() => store.dispatch(prevSlide())}
      next={() => navigate('/test/focus')}
    >
      <p>
        If you understand the rules, let's start a practice test! If something is still not clear,
        you can always come back to this tutorial from the Main Menu.
      </p>
    </TutorialDialog>
  </>
}
