import { createSlice } from '@reduxjs/toolkit'

export interface TimerState {
  time: null|number
}

const initialState = {
  time: null
} as TimerState

let interval: null|NodeJS.Timer = null

export const startTimer = () => (dispatch: any, getState: any) => {

  interval = setInterval(
    () => {
      const state: TimerState = getState().timer
      if (state.time && -1 < state.time - 1) {
        dispatch(setTimer(state.time - 1))
      } else if (interval) {
        clearInterval(interval)
      }
    },
    1000
  )
}

export const clearTimer = () => (dispatch: any) => {
  dispatch(setTimer(null))
  if (interval) {
    clearInterval(interval)
  }
}

export const timerSlice = createSlice({
  name: 'Timer',
  initialState: initialState,
  reducers: {
    setTimer: (state, action) => {
      state.time = action.payload
    }
  }
})

export const { setTimer } = timerSlice.actions
