import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import NotFound from './components/errors/NotFound'
import { Provider } from 'react-redux'
import { store } from './state/store'
import Login from './components/Login'
import Tests from './components/Tests'
import FocusGame from './components/FocusGame/FocusGame'
import MonotonityGame from './components/MonotonityGame/MonotonityGame'
import Stats from './components/Stats'
import Account from './components/Account'
import FullGame from './components/FullGame/FullGame'
import FocusGameTutorial from './components/FocusGame/FocusGameTutorial'
import MonotonityGameTutorial from './components/MonotonityGame/MonotonityGameTutorial'
import Home from './components/Home'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Home /> },
      { path: '/login', element: <Login /> },
      { path: '/test', element: <Tests /> },
      { path: '/test/focus', element: <FocusGame /> },
      { path: '/tutorial/focus', element: <FocusGameTutorial /> },
      { path: '/test/monotonity', element: <MonotonityGame /> },
      { path: '/tutorial/monotonity', element: <MonotonityGameTutorial /> },
      { path: '/test/full', element: <FullGame /> },
      { path: '/stats/:userId', element: <Stats /> },
      { path: '/account/:accountId', element: <Account /> }
    ],
    errorElement: <NotFound />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
