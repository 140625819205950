import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import {
  User,
  UserRole, UserStatus, useDescribeAccountQuery, useListAccountUsersQuery
} from '../state/apiSlice'
import { useEffect, useState } from 'react'
import { setIsLoading } from '../state/loadingSlice'
import { store } from '../state/store'
import { setError } from '../state/errorSlice'
import UserList from './UserList'
import { Chip, Divider, Paper } from '@mui/material'

export default function Account() {
  const { accountId } = useParams()
  const {
    data: account, error: accountError, isLoading: isLoadingAccount
  } = useDescribeAccountQuery(accountId!)
  const {
    data: users, error: usersError, isLoading: isLoadingUsers
  } = useListAccountUsersQuery(accountId!)
  const allFilters = ['Active', 'Inactive']
  const [ filters, setFilters ] = useState(['Active'])

  useEffect(() => {
    store.dispatch(setIsLoading(isLoadingAccount && isLoadingUsers))
    if (accountError) {
      store.dispatch(setError('An unexpected error occured. Please try again!'))
    }
    if (usersError) {
      store.dispatch(setError('An unexpected error occured. Please try again!'))
    }
  }, [accountError, isLoadingAccount, usersError, isLoadingUsers])

  function filterUsers(users: User[]): User[] {
    const filterToStatus = new Map([
      ['Active', UserStatus.ACTIVE],
      ['Inactive', UserStatus.INACTIVE]
    ])

    return users.filter(
      user => user.Role === UserRole.DRIVER &&
      filters.map(x => filterToStatus.get(x)).includes(user.Status)
    )
  }

  return <>
    <h1 className="text-6xl font-bold tracking-tight text-gray-900 p-12 text-center">
      {account?.Name}
    </h1>
    <Divider variant="middle" />
    <Paper
      className="p-2 m-3"
      component="ul"
    >
      <h2 className="mx-4">Filters:</h2>
      <div className="flex flex-wrap">
        {allFilters.map((filter) => {
          return (
            <li className="m-2" key={uuidv4()}>
              <Chip
                label={filter}
                color={filters.includes(filter) ? 'success' : 'default'}
                onClick={() => {
                  filters.includes(filter) ?
                    setFilters(filters.filter(x => x !== filter)) :
                    setFilters([...filters, filter])
                }}
              />
            </li>
          )
        })}
      </div>
    </Paper>
    <Divider variant="middle" />
    {
      users && filterUsers(users).length ?
        <UserList users={ filterUsers(users) } /> :
        <p>There are no users matching the specified conditions.</p>
    }
  </>
}
