import { PedalKind } from '../utils/pedalKind'
import { Color } from '../utils/color'
import { PitchKind } from '../utils/pitchKind'
import chooseRandomly from '../utils/chooseRandomly'

const signals = [
  Color.WHITE, Color.RED, Color.BLUE, Color.GREEN,
  Color.YELLOW, PitchKind.LOW, PitchKind.HIGH,
  PedalKind.LEFT, PedalKind.RIGHT
]

export function nextSignal(
  signal: null|Color|PitchKind|PedalKind
): Color|PitchKind|PedalKind {
  return chooseRandomly(
    signals.filter(x => signal ? x !== signal : true)
  )
}

export function nextLedIndex() {
  return Math.floor(Math.random() * 10)
}
