import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Cookies } from 'react-cookie'
import { setError } from './errorSlice'
import { store } from './store'
import { LoginResult } from './authSlice'

export const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`
})

export const baseQueryWithAuth = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}`,
  prepareHeaders: (headers) => {
    const token = new Cookies().get('IdToken')

    if (token) {
      headers.set('Authorization', token)
    }

    return headers
  }
})

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQueryWithAuth(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    await reAuthenticate(api, extraOptions)
    result = await baseQueryWithAuth(args, api, extraOptions)
  }

  return result
}

async function reAuthenticate(api: any, extraOptions: any) {
  const cookies = new Cookies()
  const refreshResult = await baseQuery(
    {
      url: '/refresh',
      method: 'POST',
      body: JSON.stringify({
        AccessToken: cookies.get('AccessToken'),
        RefreshToken: cookies.get('RefreshToken')
      })
    },
    api, extraOptions
  )
  if (refreshResult.data) {
    cookies.set(
      'IdToken',
      (refreshResult.data as LoginResult).IdToken,
      { maxAge: 3600, path: '/' }
    )
  } else {
    store.dispatch(setError('You are logged out. Please login again!'))
  }
}
