import { Color } from '../../utils/color'
import { useSelector } from 'react-redux'
import State from '../../state/state'
import {
  cleanupPhase, endRounds, initGame, setGameId, startGame, startPhase, startRounds,
  userAction
} from '../../state/focusGameSlice'
import { store } from '../../state/store'
import { useEffect } from 'react'
import { PitchKind } from '../../utils/pitchKind'
import { PedalKind } from '../../utils/pedalKind'
import { clearTimer, setTimer } from '../../state/timerSlice'
import FocusGameFragment from './FocusGameFragment'
import { GameKind } from '../../utils/gameKind'

export default function FocusGame() {
  const lifecycle = useSelector(state => (state as State).focus.lifecycle)
  const phase = useSelector(state => (state as State).focus.phase)
  const signal: null|Color|PitchKind|PedalKind = useSelector(state => (state as State).focus.signal)
  const ledIndex = useSelector(state => (state as State).focus.ledIndex)

  useEffect(() => {
    store.dispatch(initGame())
    store.dispatch(setTimer(
      parseInt(process.env.REACT_APP_FOCUS_GAME_PHASE_DURATION!) / 1000
    ))

    return () => {
      store.dispatch(endRounds())
      store.dispatch(clearTimer())
    }
  }, [])

  useEffect(() => {
    window.Howler.autoSuspend = false

    return () => {}
  }, [])

  return <FocusGameFragment
    lifecycle={lifecycle}
    phase={phase}
    signal={signal}
    ledIndex={ledIndex}
    gameKind={GameKind.FOCUS_GAME_KIND}
    setGameId={setGameId}
    startGame={startGame}
    startPhase={startPhase}
    startRounds={startRounds}
    cleanupPhase={cleanupPhase}
    userAction={userAction}
  />
}

