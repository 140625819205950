import { Color } from '../../utils/color'

export enum LedSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE'
}

interface LedProps {
  color: Color
  size: LedSize
  active: boolean
  children?: any
}

export default function Led(props: Readonly<LedProps>) {
  const colorToStyle = {
    [Color.RED]: {
      ring: 'ring-red-500/50',
      edge: 'hsl(340deg 100% 32%)',
      front: 'hsl(345deg 100% 47%)'
    },
    [Color.BLUE]: {
      ring: 'ring-blue-500/50',
      edge: 'hsl(208deg, 98%, 50%)',
      front: 'hsl(213deg, 98%, 65%)'
    },
    [Color.GREEN]: {
      ring: 'ring-green-500/50',
      edge: 'hsl(110deg, 98%, 33%)',
      front: 'hsl(124deg, 100%, 63%)'
    },
    [Color.WHITE]: {
      ring: 'ring-white/50',
      edge: 'hsl(200deg, 0%, 85%)',
      front: 'hsl(213deg, 10%, 100%)'
    },
    [Color.YELLOW]: {
      ring: 'ring-yellow-500/50',
      edge: 'hsl(55deg, 98%, 40%)',
      front: 'hsl(60deg, 98%, 50%)'
    },
    [Color.BLACK]: {
      ring: 'ring-black/50',
      edge: 'hsl(13deg, 0%, 18%)',
      front: 'hsl(26deg, 0%, 30%)'
    }
  }

  return <div className="relative flex items-center justify-center">
    <div
      className={`${props.size === LedSize.LARGE ? 'm-auto h-16 w-16' : 'h-12 w-12'}`}
    >
      <div className="h-full w-full rounded-full three-d-container">
        <span className="h-full w-full rounded-full three-d-shadow" />
        <span
          className={`h-full w-full rounded-full three-d-edge border-black ${props.active ? 'ring-8 ' + colorToStyle[props.color].ring : ''}`}
          style={{background: props.active ? colorToStyle[props.color].edge : '#cbd5e1'}}
        />
        <span
          className="h-full w-full rounded-full three-d-front border-black"
          style={{background: props.active ? colorToStyle[props.color].front : '#cbd5e1'}}
        >
          {props.children}
        </span>
      </div>
    </div>
  </div>
}
