import { createSlice } from '@reduxjs/toolkit'

export interface LoadingState {
  isLoading: boolean
}

export const loadingSlice = createSlice({
  name: 'Loading',
  initialState: { isLoading: false },
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    }
  }
})

export const { setIsLoading } = loadingSlice.actions
