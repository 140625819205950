import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import { Color } from '../../utils/color'
import { FocusGameLifecycle } from '../../state/focusGameSlice'
import { store } from '../../state/store'
import Led, { LedSize } from './Led'
import GameDialog from '../GameDialog'
import lowPitch from './low_pitch.mp3'
import highPitch from './high_pitch.mp3'
import { useDescribeCurrentUserQuery } from '../../state/apiSlice'
import Button from '../Button'
import { PitchKind } from '../../utils/pitchKind'
import { PedalKind } from '../../utils/pedalKind'
import ControlPanel from '../ControlPanel'
import ReactHowler from 'react-howler'
import FocusGameStartButton from './FocusGameStartButton'
import { GameKind } from '../../utils/gameKind'
import State from '../../state/state'
import { useSelector } from 'react-redux'
import { FullGameLifecycle } from '../../state/fullGameSlice'

interface FocusGameFragmentProps {
  lifecycle: FocusGameLifecycle|FullGameLifecycle
  phase: number
  signal: null|Color|PitchKind|PedalKind
  ledIndex: null|number
  gameKind: GameKind.FOCUS_GAME_KIND|GameKind.FULL_GAME_KIND
  setGameId: (gameId: string) => any
  startGame: () => any
  startPhase: () => any
  startRounds: (roundDuration: number) => any
  cleanupPhase: () => any
  userAction: (action: any) => any
}

export default function FocusGameFragment(props: Readonly<FocusGameFragmentProps>) {
  const { data: user } = useDescribeCurrentUserQuery('')
  const navigate = useNavigate()
  const isLoading = useSelector(state => (state as State).loading.isLoading)

  return <>
    <ReactHowler src={lowPitch} playing={props.signal === PitchKind.LOW} />
    <ReactHowler src={highPitch} playing={props.signal === PitchKind.HIGH} />
    <div className="fill-under-top-bar max-w-prose relative m-auto overflow-hidden">
      <div className="h-1/2 relative">
        <div className="h-2/3" style={{backgroundColor: '#8da7b5'}}>
          <div className="h-1/2 flex align-center justify-around">
            {
              [0, 1, 2, 3, 4].map(x => (
                <Led
                  key={uuidv4()}
                  color={props.signal as Color}
                  size={LedSize.SMALL}
                  active={props.ledIndex === x}
                />
              ))
            }
          </div>
          <div className="h-1/2 flex align-center justify-around">
            {
              [5, 6, 7, 8, 9].map(x => (
                <Led
                  key={uuidv4()}
                  color={props.signal as Color}
                  size={LedSize.SMALL}
                  active={props.ledIndex === x}
                />
              ))
            }
          </div>
        </div>
        <div className="h-1/3 flex align-center justify-around bg-slate-300 relative">
          <Led
            color={Color.WHITE}
            size={LedSize.LARGE}
            active={props.signal === PedalKind.LEFT}
          />
          <Led
            color={Color.WHITE}
            size={LedSize.LARGE}
            active={props.signal === PedalKind.RIGHT}
          />
        </div>
      </div>
      <div className="h-1/2 relative">
        <ControlPanel
          onLowPitchButtonClick={() => {
            store.dispatch(props.userAction(PitchKind.LOW))
          }}
          onHighPitchButtonClick={() => {
            store.dispatch(props.userAction(PitchKind.HIGH))
          }}
          onRedColorButtonClick={() => {
            store.dispatch(props.userAction(Color.RED))
          }}
          onBlueColorButtonClick={() => {
            store.dispatch(props.userAction(Color.BLUE))
          }}
          onWhiteColorButtonClick={() => {
            store.dispatch(props.userAction(Color.WHITE))
          }}
          onGreenColorButtonClick={() => {
            store.dispatch(props.userAction(Color.GREEN))
          }}
          onYellowColorButtonClick={() => {
            store.dispatch(props.userAction(Color.YELLOW))
          }}
          onLeftPedalButtonClick={() => {
            store.dispatch(props.userAction(PedalKind.LEFT))
          }}
          onRightPedalButtonClick={() => {
            store.dispatch(props.userAction(PedalKind.RIGHT))
          }}
        />
      </div>
    </div>

    <GameDialog show={
      [
        FocusGameLifecycle.NULL, FocusGameLifecycle.PHASE_ENDED,
        FocusGameLifecycle.GAME_ENDED,
        FullGameLifecycle.NULL, FullGameLifecycle.PHASE_ENDED,
        FullGameLifecycle.GAME_ENDED
      ].includes(props.lifecycle)
    }>
      <>
        <div className="bg-white p-6 pb-4">
          <div className="text-center">
            {props.phase === 3 ? 'Test ended.' : 'Ready?'}
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3">
          <div className="flex m-3">
            <FocusGameStartButton
              phase={props.phase}
              gameKind={props.gameKind}
              setGameId={props.setGameId}
              startGame={props.startGame}
              startPhase={props.startPhase}
              startRounds={props.startRounds}
              cleanupPhase={props.cleanupPhase}
            />
          </div>
          <div className="flex m-3">
            <Button type="button" kind="grey" onClick={() => navigate('/')} disabled={isLoading}>
              Back to Home
            </Button>
          </div>
          {
            props.phase === 3 ? (
              <div className="flex m-3">
                <Button type="button" kind="grey" onClick={() => navigate(`/stats/${user?.UserId}`)} disabled={isLoading}>
                  See Stats
                </Button>
              </div>
            ) : null
          }
        </div>
      </>
    </GameDialog>
  </>
}
