import { Link } from 'react-router-dom'
import { UserCircleIcon } from '@heroicons/react/24/solid'
import { Card, CardContent } from '@mui/material'
import { UserStatus } from '../state/apiSlice'

interface UserListItemProps {
  userId: string
  status: UserStatus
}

export default function UserListItem(props: Readonly<UserListItemProps>) {
  return <Link to={`/stats/${props.userId}`}>
    <Card variant="outlined">
      <CardContent>
        <div className="flex items-center">
          <UserCircleIcon className="w-8 h-8 m-3" />
          <div className="ms-4 me-12">
            <h3 className="text-l font-bold">{props.userId}</h3>
            <div className="flex items-center">
            {
              props.status === UserStatus.ACTIVE ? (
                <>
                  <span className="relative flex h-3 w-3 ms-0 m-2">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500" />
                  </span>
                  <p>Active</p>
                </>
              ) : (
                <>
                  <span className="relative flex h-3 w-3 ms-0 m-2">
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-500" />
                  </span>
                  <p>Inactive</p>
                </>
              )
            }
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </Link>
}
