import { v4 as uuidv4 } from 'uuid'
import ControlPanel from '../ControlPanel'
import { useSelector } from 'react-redux'
import State from '../../state/state'
import { store } from '../../state/store'
import { useEffect } from 'react'
import { nextSlide, nextTutorialRound, prevSlide, resetState } from '../../state/monotonityGameTutorialSlice'
import TutorialDialog from '../TutorialDialog'
import { useNavigate } from 'react-router-dom'

export default function MonotonityGameTutorial() {
  const navigate = useNavigate()

  const slide = useSelector(state => (state as State).monotonityTutorial.slide)
  const signal = useSelector(state => (state as State).monotonityTutorial.signal)
  const signalImage = useSelector(
    state => (state as State).monotonityTutorial.signalImage
  )
  const initialSignalImages = useSelector(
    state => (state as State).monotonityTutorial.initialSignalImages
  )

  useEffect(() => {
    store.dispatch(resetState())

    return () => { store.dispatch(resetState()) }
  }, [])

  return <>
    <div className="fill-under-top-bar max-w-prose relative m-auto">
      <div className="h-1/4 flex align-center justify-around bg-white">
        {
          initialSignalImages.map(x => (
            <div
              key={`${uuidv4()}`}
              className="bg-contain bg-center bg-no-repeat flex-1 m-3" style={{backgroundImage: `url(${x})`}}
            />
          ))
        }
      </div>
      <div className="h-1/4 flex align-center justify-center bg-white">
        <div
          className="bg-contain bg-center bg-no-repeat flex-1"
          style={{backgroundImage: `url(${signalImage})`}}
      />
      </div>
      <div className="h-1/2 relative">
        <ControlPanel
          markAction={signal}
          onLowPitchButtonClick={() => store.dispatch(nextTutorialRound())}
          onHighPitchButtonClick={() => store.dispatch(nextTutorialRound())}
        />
      </div>
    </div>

    <TutorialDialog
      show={slide === 1}
      prev={() => navigate('/')}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        During the monotonicity test you will have to decide if the signals you see are correct or not.
        The difference between correct and incorrect signals is very small but noticeable.
        Explore how to decide if a signal is correct or incorrect on the next slides!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 2}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        When the test starts, you will see 4 initial symbols on the panel on the top.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 3}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        In each round, a new symbol will appear below the initial symbols.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 4}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        If the symbol you see matches one of the initial symbols,
        click on the white rectangular button on the right.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 5}
      prev={() => store.dispatch(prevSlide())}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        If the symbol does not match any of the initial symbols
        (e.g. there is an extra line under the symbol),
        then click on the black rectangular button on the left.
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 6}
      prev={() => store.dispatch(prevSlide())}
      next={() => {
        store.dispatch(nextSlide())
        store.dispatch(nextTutorialRound())
      }}
    >
      <p>
        When you select your answer, a new symbol appears. Let's see some examples!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 8}
      prev={() => {
        store.dispatch(prevSlide())
        store.dispatch(nextTutorialRound())
      }}
      next={() => store.dispatch(nextSlide())}
    >
      <p>
        The test will go on for 7 minutes.
        You will be rated based on the number of errors you made but also on the
        number of symbols you checked. This means, that you will have to choose the
        correct answer as quickly as you can!
      </p>
    </TutorialDialog>

    <TutorialDialog
      show={slide === 9}
      prev={() => store.dispatch(prevSlide())}
      next={() => navigate('/test/monotonity')}
    >
      <p>
        If you understand the rules, let's start a practice test!
        If something is still not clear, you can always come back
        to this tutorial from the Main Menu.
      </p>
    </TutorialDialog>
  </>
}
