import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { store } from '../../state/store'
import State from '../../state/state'
import { useNavigate } from 'react-router-dom'
import GameDialog from '../GameDialog'
import ControlPanel from '../ControlPanel'
import {
  MonotonityGameLifecycle, endGame, endRound,
  initGame, setGameId, startGame, startRound, updateStats, userAction
} from '../../state/monotonityGameSlice'
import { useEffect } from 'react'
import { setIsLoading } from '../../state/loadingSlice'
import {
  GameCreatedResponse,
  useCreateGameMutation, useDescribeCurrentUserQuery
} from '../../state/apiSlice'
import { GameKind } from '../../utils/gameKind'
import { setError } from '../../state/errorSlice'
import Button from '../Button'
import { PitchKind } from '../../utils/pitchKind'
import { clearTimer, setTimer, startTimer } from '../../state/timerSlice'

export default function MonotonityGame() {
  const { data: user } = useDescribeCurrentUserQuery('')
  const [createGame, _] = useCreateGameMutation()

  const navigate = useNavigate()

  const isLoading = useSelector(state => (state as State).loading.isLoading)
  const lifecycle = useSelector(state => (state as State).monotonity.lifecycle)
  const signalImage = useSelector(
    state => (state as State).monotonity.signalImage
  )
  const initialSignalImages = useSelector(
    state => (state as State).monotonity.initialSignalImages
  )

  useEffect(() => {
    store.dispatch(initGame())
    store.dispatch(setTimer(
      parseInt(process.env.REACT_APP_MONOTONITY_GAME_DURATION!) / 1000
    ))

    return () => { store.dispatch(clearTimer()) }
  }, [])

  function play() {
    store.dispatch(startTimer())
    store.dispatch(startGame())
    store.dispatch(startRound())

    setTimeout(() => {
      store.dispatch(endGame())
      store.dispatch(updateStats())
    }, parseInt(process.env.REACT_APP_MONOTONITY_GAME_DURATION!))
  }

  return <>
    <div className="fill-under-top-bar max-w-prose relative m-auto">
      <div className="h-1/4 flex align-center justify-around bg-white">
        {
          initialSignalImages.map(x => (
            <div
              key={`${uuidv4()}`}
              className="bg-contain bg-center bg-no-repeat flex-1 m-3" style={{backgroundImage: `url(${x})`}}
            />
          ))
        }
      </div>
      <div className="h-1/4 flex align-center justify-center bg-white">
        <div
          className="bg-contain bg-center bg-no-repeat flex-1"
          style={{backgroundImage: `url(${signalImage})`}}
      />
      </div>
      <div className="h-1/2 relative">
        <ControlPanel
          onLowPitchButtonClick={() => {
            store.dispatch(userAction(PitchKind.LOW))
            store.dispatch(endRound())
            store.dispatch(startRound())
          }}
          onHighPitchButtonClick={() => {
            store.dispatch(userAction(PitchKind.HIGH))
            store.dispatch(endRound())
            store.dispatch(startRound())
          }}
        />
      </div>
    </div>

    <GameDialog show={
      [
        MonotonityGameLifecycle.NULL, MonotonityGameLifecycle.GAME_ENDED
      ].includes(lifecycle)
    }>
      <>
        <div className="bg-white p-6 pb-4">
          <div className="text-center">
            {
              lifecycle === MonotonityGameLifecycle.GAME_ENDED ?
                'Test ended.' : 'Ready?'
            }
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3">
          {
            <div className="flex m-3">
              <Button
                type="button"
                kind="green"
                onClick={() => {
                  store.dispatch(setIsLoading(true))
                  createGame(GameKind.MONOTONITY_GAME_KIND)
                    .then((response) => {
                      store.dispatch(setIsLoading(false))
                      store.dispatch(setGameId(
                        (response as { data: GameCreatedResponse }).data.GameId)
                      )
                      play()
                    })
                    .catch(() => {
                      store.dispatch(
                        setError('An unexpected error occured. Please try again!')
                      )
                    })
                }}
              >
                {lifecycle === MonotonityGameLifecycle.GAME_ENDED ? 'New Test' : 'Start'}
              </Button>
            </div>
          }
          <div className="flex m-3">
            <Button type="button" kind="grey" onClick={() => navigate('/')} disabled={isLoading}>
              Back to Home
            </Button>
          </div>
          {
            lifecycle === MonotonityGameLifecycle.GAME_ENDED ? (
              <div className="flex m-3">
                <Button type="button" kind="grey" onClick={() => navigate(`/stats/${user?.UserId}`)} disabled={isLoading}>
                  See Stats
                </Button>
              </div>
            ) : null
          }
        </div>
      </>
    </GameDialog>
  </>
}
