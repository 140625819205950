import { createApi } from '@reduxjs/toolkit/query/react'
import { GameKind } from '../utils/gameKind'
import { baseQueryWithReauth } from './baseQuery'

export interface Account {
  AccountId: string
  Name: string
}

export interface User {
  AccountId: string,
  UserId: string,
  Role: UserRole,
  Status: UserStatus,
  Stats: UserStat[]
}

export enum UserRole {
  ADMIN = 'ADMIN',
  DRIVER = 'DRIVER'
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface UserStat {
  Kind: GameKind
  TopAccuracy: number
  TopHits?: number
}

export interface Game {
  UserId: string
  GameId: string
  Kind: GameKind
  Timestamp: Date
  Stats?: GameStat[]
}

export interface GameStat {
  Kind: GameKind
  Hits: number
  Misses: number
  RoundDuration?: number
}

export interface GameCreatedResponse {
  GameId: string
}

export interface GameUpdatedResponse {

}

export const pavApi = createApi({
  reducerPath: 'pavApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createGame: builder.mutation<GameCreatedResponse, GameKind>({
      query: (kind) => ({
        url: '/game',
        method: 'POST',
        body: JSON.stringify({ Kind: kind })
      })
    }),
    updateGame: builder.mutation<
      GameUpdatedResponse, GameStat & Pick<Game, 'GameId'
    >>({
      query: ({GameId, ...stats}) => ({
        url: `/game/${GameId}`,
        method: 'PUT',
        body: JSON.stringify(stats)
      })
    }),
    describeAccount: builder.query<Account, string>({
      query: (accountId) => `account/${accountId}`
    }),
    listAccountUsers: builder.query<User[], string>({
      query: (accountId) => `account/${accountId}/users`
    }),
    describeCurrentUser: builder.query<User, string>({
      query: () => 'user'
    }),
    listUserGames: builder.query<Game[], string>({
      query: (userId) => `user/${userId}/games`
    })
  })
})

export const {
  useCreateGameMutation, useUpdateGameMutation,
  useDescribeAccountQuery, useListAccountUsersQuery,
  useDescribeCurrentUserQuery, useLazyDescribeCurrentUserQuery,
  useListUserGamesQuery
} = pavApi
