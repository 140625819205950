import useSound from 'use-sound'
import click from './click.mp3'
import { Color } from '../utils/color'

export enum ThreeDButtonKind {
  ROUND = 'ROUND',
  SQUARE = 'SQUARE'
}

export enum ThreeDButtonSize {
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

interface ThreeDButtonProps {
  color: Color
  marked?: boolean
  kind?: ThreeDButtonKind,
  size?: ThreeDButtonSize,
  children?: any
  onClick?: () => any
}

export default function ThreeDButton(props: Readonly<ThreeDButtonProps>) {
  const [playClick] = useSound(click)

  const colorToStyle = {
    [Color.RED]: {
      edge: 'hsl(340deg 100% 32%)',
      front: 'hsl(345deg 100% 47%)'
    },
    [Color.BLUE]: {
      edge: 'hsl(208deg, 98%, 50%)',
      front: 'hsl(213deg, 98%, 65%)'
    },
    [Color.GREEN]: {
      edge: 'hsl(110deg, 98%, 33%)',
      front: 'hsl(124deg, 100%, 63%)'
    },
    [Color.WHITE]: {
      edge: 'hsl(200deg, 0%, 85%)',
      front: 'hsl(213deg, 10%, 100%)'
    },
    [Color.YELLOW]: {
      edge: 'hsl(55deg, 98%, 40%)',
      front: 'hsl(60deg, 98%, 50%)'
    },
    [Color.BLACK]: {
      edge: 'hsl(26deg, 0%, 30%)',
      front: 'hsl(13deg, 0%, 18%)'
    }
  }

  return <div
    className={`${props.size === ThreeDButtonSize.LARGE ? 'h-full w-1/5' : 'relative flex items-center justify-center'}`}
  >
    <div className={`${props.size === ThreeDButtonSize.LARGE ? 'h-full w-full' : 'w-16 h-16'}`}>
      <button
        className={`${props.kind === ThreeDButtonKind.ROUND ? 'rounded-full' : 'rounded'} h-full w-full three-d-container pushable`}
        onClick={() => {
          playClick()
          if (props.onClick) {
            props.onClick()
          }
        }}
      >
        {
          props.marked ? (
            <span
              className={`${props.kind === ThreeDButtonKind.ROUND ? 'rounded-full' : 'rounded'} animate-ping absolute top-0 bottom-0 left-0 right-0 bg-green-400 opacity-75`}
            />
          ) : null
        }
        <span
          className={`${props.kind === ThreeDButtonKind.ROUND ? 'rounded-full' : 'rounded'} h-full w-full three-d-shadow`}
        />
        <span
          className={`${props.kind === ThreeDButtonKind.ROUND ? 'rounded-full' : 'rounded'} three-d-edge ${props.color === Color.BLACK ? 'border-white' : 'border-black'}`}
          style={{background: colorToStyle[props.color].edge}}
        />
        <span
          className={`${props.kind === ThreeDButtonKind.ROUND ? 'rounded-full' : 'rounded'} h-full w-full three-d-front ${props.color === Color.BLACK ? 'border-white' : 'border-black'}`}
          style={{background: colorToStyle[props.color].front}}
        >
          {props.children}
        </span>
      </button>
    </div>
  </div>
}
