import { v4 as uuidv4 } from 'uuid'
import {
  Chip,
  Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { GameKind } from '../utils/gameKind'
import { Game, GameStat, useListUserGamesQuery } from '../state/apiSlice'
import { store } from '../state/store'
import { setIsLoading } from '../state/loadingSlice'
import { useEffect, useState } from 'react'
import { setError } from '../state/errorSlice'
import ResultMarker from './ResultMarker'

export default function Stats() {
  const { userId } = useParams()
  const { data: games, error, isLoading } = useListUserGamesQuery(userId!)
  const allColumns = [
    'Focus Test - Phase 1', 'Focus Test - Phase 2',
    'Focus Test - Phase 3', 'Focus Test - Aggregated',
    'Monotonicity Test', 'Aggregated'
  ]
  const [ columns, setColumns ] = useState([
    'Focus Test - Aggregated', 'Monotonicity Test', 'Aggregated'
  ])

  useEffect(() => {
    store.dispatch(setIsLoading(isLoading))
    if (error) {
      store.dispatch(setError('An unexpected error occured. Please try again!'))
    }
  }, [error, isLoading])

  function transformGames(games: Game[]) {
    return games
      .map(x => x)
      .sort((a, b) => a.Timestamp < b.Timestamp ? 1 : -1)
      .filter(game => game.Stats)
      .map(game => {
        const timestamp = game.Timestamp

        let focusGamePhase1Result = null
        let focusGamePhase2Result = null
        let focusGamePhase3Result = null
        let focusGameAggregatedResult = null
        let monotonityGameResult = null
        let aggregatedResult = null

        if (game.Stats) {
          const focusGameStats = game.Stats
            .filter(stat => stat.Kind === GameKind.FOCUS_GAME_KIND)

          if (0 < focusGameStats.length) {
            focusGamePhase1Result = getFocusGamePhaseResult(focusGameStats[0])
          }
          if (1 < focusGameStats.length) {
            focusGamePhase2Result = getFocusGamePhaseResult(focusGameStats[1])
          }
          if (2 < focusGameStats.length) {
            focusGamePhase3Result = getFocusGamePhaseResult(focusGameStats[2])
          }
          focusGameAggregatedResult = getFocusGameAggregatedResult(
            focusGamePhase1Result ?? 0,
            focusGamePhase2Result ?? 0,
            focusGamePhase3Result ?? 0
          )
          const monotonityGames = game.Stats
            .filter(stat => stat.Kind === GameKind.MONOTONITY_GAME_KIND)
          monotonityGameResult = monotonityGames.length ?
            getMonotonityGameResult(monotonityGames[0]) : null
          
          if (focusGameAggregatedResult && monotonityGameResult) {
            aggregatedResult = getAggregatedResult(
              focusGameAggregatedResult, monotonityGameResult
            )
          }
        }

        return {
          timestamp,
          focusGamePhase1Result,
          focusGamePhase2Result,
          focusGamePhase3Result,
          focusGameAggregatedResult,
          monotonityGameResult,
          aggregatedResult
        }
      })
  }

  function getFocusGamePhaseResult(stat: GameStat) {
    return stat.Hits / (stat.Hits + stat.Misses)
  }

  function getFocusGameAggregatedResult(
    phase1Result: number, phase2Result: number, phase3Result: number
  ) {
    return (phase1Result + phase2Result + phase3Result) / 3
  }

  function getMonotonityGameResult(stat: GameStat) {
    return stat.Hits / (stat.Hits + stat.Misses)
  }

  function getAggregatedResult(
    focusGameAggregatedResult: number, monotonityGameResult: number
  ) {
    return (focusGameAggregatedResult + monotonityGameResult) / 2
  }

  return <>
    <Paper
      className="p-2 my-2"
      component="ul"
    >
      <h2 className="mx-4">Columns:</h2>
      <div className="flex flex-wrap">
        {allColumns.map((column) => {
          return (
            <li className="m-2" key={uuidv4()}>
              <Chip
                label={column}
                color={columns.includes(column) ? 'success' : 'default'}
                onClick={() => {
                  columns.includes(column) ?
                    setColumns(columns.filter(x => x !== column)) :
                    setColumns([...columns, column])
                }}
              />
            </li>
          )
        })}
      </div>
    </Paper>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="stats table">
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            {
              columns.includes('Focus Test - Phase 1') ? (
                <TableCell>Focus Test - Phase 1</TableCell>
              ) : null
            }
            {
              columns.includes('Focus Test - Phase 2') ? (
                <TableCell>Focus Test - Phase 2</TableCell>
              ) : null
            }
            {
              columns.includes('Focus Test - Phase 3') ? (
                <TableCell>Focus Test - Phase 3</TableCell>
              ) : null
            }
            {
              columns.includes('Focus Test - Aggregated') ? (
                <TableCell>Focus Test - Aggregated</TableCell>
              ) : null
            }
            {
              columns.includes('Monotonicity Test') ? (
                <TableCell>Monotonicity Test</TableCell>
              ) : null
            }
            {
              columns.includes('Aggregated') ? (
                <TableCell>Aggregated</TableCell>
              ) : null
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            games?.length ? (
              transformGames(games).map((row: any) => (
                <TableRow
                  key={row.timestamp}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{ row.timestamp }</TableCell>
                  {
                    columns.includes('Focus Test - Phase 1') ? (
                      <TableCell>
                        <ResultMarker result={row.focusGamePhase1Result} />
                      </TableCell>
                    ) : null
                  }
                  {
                    columns.includes('Focus Test - Phase 2') ? (
                      <TableCell>
                        <ResultMarker result={row.focusGamePhase2Result} />
                      </TableCell>
                    ) : null
                  }
                  {
                    columns.includes('Focus Test - Phase 3') ? (
                      <TableCell>
                        <ResultMarker result={row.focusGamePhase3Result} />
                      </TableCell>
                    ) : null
                  }
                  {
                    columns.includes('Focus Test - Aggregated') ? (
                      <TableCell>
                        <ResultMarker result={row.focusGameAggregatedResult} />
                      </TableCell>
                    ) : null
                  }
                  {
                    columns.includes('Monotonicity Test') ? (
                      <TableCell>
                        <ResultMarker result={row.monotonityGameResult} />
                      </TableCell>
                    ) : null
                  }
                  {
                    columns.includes('Aggregated') ? (
                      <TableCell>
                        <ResultMarker result={row.aggregatedResult} />
                      </TableCell>
                    ) : null
                  }
                </TableRow>
              
              ))
            ) : (
              <TableRow><TableCell colSpan={columns.length + 1}>This user has no stats yet.</TableCell></TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  </>
}
