import tests from '../tests.jpg'
import stats from '../stats.jpg'
import manageAccount from '../manage_account.jpg'
import { UserRole, useDescribeCurrentUserQuery } from '../state/apiSlice'
import { Link } from 'react-router-dom'

export default function Home() {
  const { data: user } = useDescribeCurrentUserQuery('')

  return <ul className="max-w-prose relative m-auto">
    {
      user?.Role === UserRole.DRIVER ? <>
        <li className="m-3">
            <Link
              to="/test"
              className="w-full md:flex rounded shadow-lg"
            >
            <div
              className="h-48 min-w-48 bg-cover text-center overflow-hidden border-b md:border-r"
              style={{backgroundImage: `url(${tests})`}}
              title="Tests"
            />
            <div className="bg-white p-4 w-full flex flex-col justify-between leading-normal">
              <div className="mb-8">
                <div className="text-gray-900 font-bold text-xl mb-2">
                  Tests
                </div>
                <p className="text-gray-700 text-base">
                  Practice for the PAV exam.
                </p>
              </div>
            </div>
          </Link>
        </li>
        <li className="m-3">
          <Link
            to={`/stats/${user?.UserId}`}
            className="w-full md:flex rounded shadow-lg"
          >
            <div
              className="h-48 min-w-48 bg-cover text-center overflow-hidden border-b md:border-r"
              style={{backgroundImage: `url(${stats})`}}
              title="Stats"
            />
            <div className="bg-white p-4 w-full flex flex-col justify-between leading-normal">
              <div className="mb-8">
                <div className="text-gray-900 font-bold text-xl mb-2">
                  Stats
                </div>
                <p className="text-gray-700 text-base">
                  Check your progress.
                </p>
              </div>
            </div>
          </Link>
        </li>
      </> : <li className="m-3">
          <Link
            to={`/account/${user?.AccountId}`}
            className="w-full md:flex rounded shadow-lg"
          >
          <div
            className="h-48 min-w-48 bg-cover text-center overflow-hidden border-b md:border-r"
            style={{backgroundImage: `url(${manageAccount})`}}
            title="Manage account"
          />
          <div className="bg-white p-4 w-full flex flex-col justify-between leading-normal">
            <div className="mb-8">
              <div className="text-gray-900 font-bold text-xl mb-2">
                Manage account
              </div>
              <p className="text-gray-700 text-base">
                Check users' performance on the practice tests.
              </p>
            </div>
          </div>
        </Link>
      </li>
    }
  </ul>
}
