import { useNavigate } from 'react-router-dom'
import useQuery from '../utils/useQuery'
import { useCookies } from 'react-cookie'
import { useState } from 'react'
import { store } from '../state/store'
import { setError } from '../state/errorSlice'
import Toast from './Toast'
import { useSelector } from 'react-redux'
import State from '../state/state'
import Button from './Button'
import { useLazyLoginQuery } from '../state/authSlice'

export default function Login() {
  const [login] = useLazyLoginQuery()
  const [_, setCookie, __] = useCookies()
  const navigate = useNavigate()
  const query = useQuery()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const error = useSelector(state => (state as State).error.error)

  async function initiateLogin(event: any) {
    event.preventDefault()

    return login({
      Username: username,
      Password: password
    })
    .then(result => {
      if (result.error) {
        store.dispatch(setError('The username or password is invalid!'))
      } else {
        // Keep the id token for 1 hour,
        // the access token for 1 day,
        // and the refresh token for 1 month
        setCookie('AccessToken', result.data?.AccessToken, { maxAge: 86400, path: '/' })
        setCookie('RefreshToken', result.data?.RefreshToken, { maxAge: 2592000, path: '/' })
        setCookie('IdToken', result.data?.IdToken, { maxAge: 3600, path: '/' })
        
        const next = query.get('next') ?? '/'
        navigate(next)
      }
    })
    .catch(() => {
      store.dispatch(setError('An unexpected error occured. Please try again!'))
    })
  }

  return <>
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={initiateLogin}>
          <div>
            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
              Username
            </label>
            <div className="mt-2">
              <input
                id="username"
                name="username"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={username}
                onChange={ev => setUsername(ev.target.value)}
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                value={password}
                onChange={ev => setPassword(ev.target.value)}
              />
            </div>
          </div>

          <div>
            <Button type="submit" kind="blue">Log in</Button>
          </div>
        </form>
      </div>
    </div>
    <Toast show={!!error} message={error} />
  </>
}
