import signal1 from '../components/MonotonityGame/signal1.png'
import signal2 from '../components/MonotonityGame/signal2.png'
import signal3 from '../components/MonotonityGame/signal3.png'
import signal4 from '../components/MonotonityGame/signal4.png'
import { createSlice } from '@reduxjs/toolkit'
import { PitchKind } from '../utils/pitchKind'
import { nextSignal, nextSignalImage } from './monotonityGame'

export interface MonotonityGameTutorialState {
  roundCounter: number,
  signal: null|PitchKind,
  signalImage: null|string,
  initialSignalImages: string[]
  slide: number
}

const initialState = {
  roundCounter: 0,
  signal: null,
  signalImage: null,
  initialSignalImages: [signal1, signal2, signal3, signal4],
  slide: 1
} as MonotonityGameTutorialState

export const monotonityGameTutorialSlice = createSlice({
  name: 'MonotonityGameTutorial',
  initialState: initialState,
  reducers: {
    resetState: state => {
      state.roundCounter = initialState.roundCounter
      state.signal = initialState.signal
      state.signalImage = initialState.signalImage
      state.initialSignalImages = initialState.initialSignalImages
      state.slide = initialState.slide
    },
    nextTutorialRound: state => {
      if (state.roundCounter < 5) {
        state.roundCounter += 1
        const signal = nextSignal()
        state.signal = signal
        const prevSignalImage = state.signalImage
        state.signalImage = nextSignalImage(
          signal, state.initialSignalImages, prevSignalImage
        )
      } else {
        state.roundCounter = initialState.roundCounter
        state.slide += 1
      }
    },
    nextSlide: state => {
      state.slide += 1
    },
    prevSlide: state => {
      state.slide -= 1
    }
  }
})

export const {
  resetState, nextTutorialRound, nextSlide, prevSlide
} = monotonityGameTutorialSlice.actions
