import { configureStore } from '@reduxjs/toolkit'
import { focusGameSlice } from './focusGameSlice'
import { monotonityGameSlice } from './monotonityGameSlice'
import { fullGameSlice } from './fullGameSlice'
import { errorSlice } from './errorSlice'
import { loadingSlice } from './loadingSlice'
import { pavApi } from './apiSlice'
import { authApi } from './authSlice'
import { focusGameTutorialSlice } from './focusGameTutorialSlice'
import { monotonityGameTutorialSlice } from './monotonityGameTutorialSlice'
import { timerSlice } from './timerSlice'

export const store = configureStore({
  reducer: {
    fullGame: fullGameSlice.reducer,
    focus: focusGameSlice.reducer,
    focusTutorial: focusGameTutorialSlice.reducer,
    monotonity: monotonityGameSlice.reducer,
    monotonityTutorial: monotonityGameTutorialSlice.reducer,
    loading: loadingSlice.reducer,
    error: errorSlice.reducer,
    timer: timerSlice.reducer,
    [pavApi.reducerPath]: pavApi.reducer,
    [authApi.reducerPath]: authApi.reducer
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware()
      .concat(pavApi.middleware)
      .concat(authApi.middleware)
  )
})

if (process.env.NODE_ENV === 'development') {
  store.subscribe(() => console.log(store.getState()))
}
