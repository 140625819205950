import { User } from '../state/apiSlice'
import UserListItem from './UserListItem'

interface UserListProps {
  users?: User[]
}

export default function UserList(props: Readonly<UserListProps>) {
  return <ul className="lists-none flex flex-wrap items-center">
    {
      props.users?.map(
        user => <li key={user.UserId} className="w-full m-3 md:w-auto">
          <UserListItem userId={user.UserId} status={user.Status} />
        </li>
      )
    }
  </ul>
}
