interface ButtonProps {
  type: 'button'|'submit'|'reset'|undefined
  kind: 'green'|'grey'|'blue'
  children: any
  onClick?: any
  disabled?: boolean
}

export default function Button(props: Readonly<ButtonProps>) {
  let extraClasses
  
  switch(props.kind) {
    case 'green':
      extraClasses = 'bg-green-600 hover:bg-green-500 focus-visible:outline-green-900 text-white'

      break
    case 'blue':
      extraClasses = 'bg-blue-500 hover:bg-blue-600 focus-visible:outline-blue-700 text-white'

      break
    case 'grey':
      extraClasses = 'bg-slate-300 hover:bg-slate-200 focus-visible:outline-slate-400 text-black'

      break
  }

  return <button
    type={props.type}
    disabled={props.disabled}
    onClick={props.onClick}
    className={`flex w-full justify-center rounded-md px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${extraClasses}`}
  >
    {props.children}
  </button>
}
