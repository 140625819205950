import signal1 from '../components/MonotonityGame/signal1.png'
import signal2 from '../components/MonotonityGame/signal2.png'
import signal3 from '../components/MonotonityGame/signal3.png'
import signal4 from '../components/MonotonityGame/signal4.png'
import signal5 from '../components/MonotonityGame/signal5.png'
import signal6 from '../components/MonotonityGame/signal6.png'
import chooseRandomly from '../utils/chooseRandomly'
import { PitchKind } from '../utils/pitchKind'

const signals = [PitchKind.LOW, PitchKind.HIGH]

const originSignalImages = [signal1, signal2, signal3, signal4]

export function generateInitialSignalImages() {
  return originSignalImages
}

export function nextSignal(): PitchKind {
  return chooseRandomly(signals)
}

const incorrectSignalImages = [signal5, signal6]

export function nextSignalImage(
  signal: PitchKind, initialSignalImages: string[],
  prevSignalImage: string|null
) {
  let result = ''

  switch(signal) {
    case PitchKind.HIGH:
      result = chooseRandomly(
        initialSignalImages.filter(x => x !== prevSignalImage)
      )

      break
    case PitchKind.LOW:
      result = chooseRandomly(
        incorrectSignalImages.filter(x => x !== prevSignalImage)
      )

      break
  }

  return result
}
