import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/solid'

interface ResultMarkerProps {
  result: null|number
}

export default function ResultMarker(props: Readonly<ResultMarkerProps>) {
  function getContent(result: null|number) {
    let content = <span>N/A</span>

    if (result !== null) {
      content = result > 0.5 ? (
        <CheckCircleIcon className="h-6 w-6 text-green-600" />
      ) : (
        <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
      )
    }
    
    return content
  }

  return <>{
    getContent(props.result)
  }</>
}
