import { v4 as uuidv4 } from 'uuid'
import { useSelector } from 'react-redux'
import { store } from '../../state/store'
import State from '../../state/state'
import { useNavigate } from 'react-router-dom'
import GameDialog from '../GameDialog'
import { useDescribeCurrentUserQuery } from '../../state/apiSlice'
import ControlPanel from '../ControlPanel'
import {
  FullGameLifecycle, endMonotonityGameRound, monotonityGameUserAction,
  startMonotonityGame, startMonotonityGameRound, updateMonotonityGameStats,
  endMonotonityGame
} from '../../state/fullGameSlice'
import Button from '../Button'
import { PitchKind } from '../../utils/pitchKind'
import { clearTimer, setTimer, startTimer } from '../../state/timerSlice'
import { useEffect } from 'react'

export default function MonotonityGame() {
  const { data: user } = useDescribeCurrentUserQuery('')

  const navigate = useNavigate()

  const isLoading = useSelector(state => (state as State).loading.isLoading)
  const lifecycle = useSelector(state => (state as State).fullGame.lifecycle)
  const signalImage = useSelector(
    state => (state as State).fullGame.monotonityGameSignalImage
  )
  const initialSignalImages = useSelector(
    state => (state as State).fullGame.monotonityGameInitialSignalImages
  )

  useEffect(() => {
    store.dispatch(setTimer(
      parseInt(process.env.REACT_APP_MONOTONITY_GAME_DURATION!) / 1000
    ))

    return () => { store.dispatch(clearTimer()) }
  }, [])

  function play() {
    store.dispatch(startTimer())
    store.dispatch(startMonotonityGame())
    store.dispatch(startMonotonityGameRound())

    setTimeout(() => {
      store.dispatch(updateMonotonityGameStats())
      store.dispatch(endMonotonityGame())
    }, parseInt(process.env.REACT_APP_MONOTONITY_GAME_DURATION!))
  }

  return <>
    <div className="fill-under-top-bar max-w-prose relative m-auto">
      <div className="h-1/4 flex align-center justify-around bg-white">
        {
          initialSignalImages.map(x => (
            <div
              key={`${uuidv4()}`}
              className="bg-contain bg-center bg-no-repeat flex-1 m-3" style={{backgroundImage: `url(${x})`}}
            />
          ))
        }
      </div>
      <div className="h-1/4 flex align-center justify-center bg-white">
        <div
          className="bg-contain bg-center bg-no-repeat flex-1"
          style={{backgroundImage: `url(${signalImage})`}}
      />
      </div>
      <div className="h-1/2 relative">
        <ControlPanel
          onLowPitchButtonClick={() => {
            store.dispatch(monotonityGameUserAction(PitchKind.LOW))
            store.dispatch(endMonotonityGameRound())
            store.dispatch(startMonotonityGameRound())
          }}
          onHighPitchButtonClick={() => {
            store.dispatch(monotonityGameUserAction(PitchKind.HIGH))
            store.dispatch(endMonotonityGameRound())
            store.dispatch(startMonotonityGameRound())
          }}
        />
      </div>
    </div>

    <GameDialog show={
      [
        FullGameLifecycle.NULL, FullGameLifecycle.GAME_ENDED
      ].includes(lifecycle)
    }>
      <>
        <div className="bg-white p-6 pb-4">
          <div className="text-center">
            { lifecycle === FullGameLifecycle.GAME_ENDED ? 'Test ended.' : 'Ready?' }
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3">
          {
            lifecycle !== FullGameLifecycle.GAME_ENDED ? (
              <div className="flex m-3">
                <Button type="button" kind="green" onClick={() => play()}>
                  Start
                </Button>
              </div>
            ) : null
          }
          <div className="flex m-3">
            <Button type="button" kind="grey" onClick={() => navigate('/')} disabled={isLoading}>
              Back to Home
            </Button>
          </div>
          {
            lifecycle === FullGameLifecycle.GAME_ENDED ? (
              <div className="flex m-3">
                <Button type="button" kind="grey" onClick={() => navigate(`/stats/${user?.UserId}`)} disabled={isLoading}>
                  See Stats
                </Button>
              </div>
            ) : null
          }
        </div>
      </>
    </GameDialog>
  </>
}
