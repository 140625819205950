import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQuery } from './baseQuery'

export interface LoginRequest {
  Username: string
  Password: string
}

export interface LoginResult {
  IdToken: string
  AccessToken: string
  RefreshToken: string
}

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    login: builder.query<LoginResult, LoginRequest>({
      query: (loginRequest) => ({
        url: '/login', method: 'POST', body: JSON.stringify(loginRequest)
      })
    })
  })
})

export const { useLazyLoginQuery } = authApi
